import React, { useEffect, useState } from "react";
import AccountNav from "../../components/Shared/AccountNav/AccountNav";
import NavigationMenu from "./NavigationMenu";
import { HomeIcon, Phone } from "lucide-react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/Select";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  get_userinfo,
  messageClear,
  update_profile,
  upload_profile_photo,
} from "../../store/reducers/HomeReducer";
import toast from "react-hot-toast";
import { countries, usStates } from "../../utils/commons";
import { MdModeEdit } from "react-icons/md";

const API_URL = process.env.REACT_APP_API_URL;

const Profile = () => {
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState("New York");
  const [country, setCountry] = useState("United States");
  const [zipCode, setZipCode] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [originalPassword, setOriginalPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const dispatch = useDispatch();
  const {
    loading: loader,
    successMessage,
    errorMessage,
    user_info,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (user_info) {
      setFirstName(user_info?.first_name || "");
      setLastName(user_info?.last_name || "");
      setEmail(user_info?.email || "");
      setNumber(user_info?.mobile_no || "");
      setAddress(user_info?.address?.address_line1 || "");
      setAddress2(user_info?.address?.address_line2 || "");
      setCity(user_info?.address?.city || "");
      setSelectedState(user_info?.address?.state || "New York");
      setCountry(user_info?.address?.country || "United States");
      setZipCode(user_info?.address?.pincode || "");
    }
  }, [user_info]);

  useEffect(() => {
    dispatch(get_userinfo());
  }, [dispatch]);

  const pass = localStorage.getItem("userSecret");
  const bytes = CryptoJS.AES.decrypt(pass, "secretkenotoday");
  const key = bytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (key) {
      setOriginalPassword(key);
    }
  }, [key]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      setCurrentPassword("");
      setNewPassword("");
      dispatch(get_userinfo());
    } else if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  //functions
  // Regular expression for validating ZIP Code (basic 5-digit or ZIP+4 format)
  const zipRegex = /^\d{5}(-\d{4})?$/;

  // Helper function to validate a single ZIP Code
  const validateSingleZipCode = (zipCode) => {
    const isZipValid = zipRegex.test(zipCode);
    if (!isZipValid) {
      toast.error("Please enter a valid ZIP Code.");
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    // Validate ZIP Code before proceeding
    const isZipCodeValid = validateSingleZipCode(zipCode);
    if (!isZipCodeValid) return;

    // Proceed if ZIP Code is valid and other fields are filled
    if (
      firstName &&
      lastName &&
      number &&
      email &&
      address &&
      city &&
      zipCode &&
      selectedState &&
      country
    ) {
      setIsSaving(true);
      try {
        await dispatch(
          update_profile({
            first_name: firstName,
            last_name: lastName,
            mobile_no: number,
            email,
            billing_address: {
              address_line1: address,
              address_line2: address2,
              city,
              state: selectedState,
              pincode: zipCode,
              country,
            },
          })
        );
      } finally {
        setIsSaving(false);
      }
    } else {
      toast.error("Fill all Inputs!");
    }
  };

  const handleChangePassword = async () => {
    if (currentPassword && newPassword) {
      setIsChangingPassword(true);
      try {
        await dispatch(
          changePassword({
            current_password: currentPassword,
            new_password: newPassword,
          })
        );
      } finally {
        setIsChangingPassword(false);
      }
    } else {
      toast.error("Please Enter Password!");
    }
  };

  const updateProfilePicture = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const form = new FormData();
        form.append("image_file", file); // Attach file to form data
        dispatch(upload_profile_photo(form));
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  return (
    <div>
      <div className="relative overflow-hidden -mt-1 bg-black">
        <div className="absolute w-full flex items-center justify-center">
          <img
            src="/assets/productbanner.jpeg"
            alt="Checkout"
            className="brightness-50 mt-[10] md:mt-[-50%] absolute border w-full"
          />
        </div>
        <div className="py-6 text-white relative container font-light px-3">
          <div className="flex items-center gap-2">
            <Link to={"/"}>
              <HomeIcon />
            </Link>
            <FaAngleRight />
            <p>Account</p>
            <FaAngleRight />
            <p className="text-yellow-500">Profile</p>
          </div>
        </div>
      </div>
      <div className="container flex gap-10 h-full bg-white md:px-8">
        <div className="hidden lg:block max-w-[300px] w-full">
          <AccountNav />
        </div>
        <div className="rounded-lg w-full my-5">
          <div className="p-4 block md:hidden sticky top-0 bg-white z-20">
            <div className="border px-2 py-3 rounded-lg flex items-center justify-between">
              <h3 className="font-semibold">Manage My Account</h3>
              <NavigationMenu />
            </div>
          </div>
          <div className="flex flex-col gap-6 p-5 shadow-md shadow-neutral-100 rounded-2xl">
            <div className="flex">
              <h2 className="py-1 text-lg font-semibold border-b-2">
                My Profile
              </h2>
            </div>
            <div className="size-[70px] rounded-full relative cursor-pointer">
              <img
                src={
                  user_info?.user_image
                    ? `${API_URL}${user_info?.user_image}`
                    : "/assets/usericon.png"
                }
                alt="user"
                className="w-[70px] border-2 border-ourPrimary rounded-full cursor-pointer overflow-hidden"
              />
              <input
                type="file"
                onChange={(e) => updateProfilePicture(e)}
                className="absolute h-full w-full left-0 top-0 opacity-0 cursor-pointer"
              />
              <div className="absolute size-7 right-0 bottom-0 bg-black/50 rounded-full flex items-center justify-center text-white pointer-events-none">
                <MdModeEdit />
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    First Name
                  </p>
                  <input
                    type="text"
                    placeholder="Your First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Last Name
                  </p>
                  <input
                    type="text"
                    placeholder="Your Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Email Address
                  </p>
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Mobile Number
                  </p>
                  <input
                    type="number"
                    placeholder="Your Mobile Number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
              </div>
              <div className="my-3 border-y py-5">
                <div className="text-sm font-semibold text-neutral-600 mb-3">
                  Address <span className="text-red-500">*</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="w-full">
                    <p className="text-sm font-medium text-neutral-600 mb-1">
                      Address Line 1
                    </p>
                    <input
                      type="text"
                      placeholder="e.g. Nikunja 2, Dhaka-North, Dhaka"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="px-5 py-2 rounded-lg bg-transparent border w-full"
                    />
                  </div>
                  <div className="w-full">
                    <p className="text-sm font-medium text-neutral-600 mb-1">
                      Address Line 2
                    </p>
                    <input
                      type="text"
                      placeholder="e.g. Nikunja 2, Dhaka-North, Dhaka"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      className="px-5 py-2 rounded-lg bg-transparent border w-full"
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    City
                  </p>
                  <input
                    type="text"
                    placeholder="Your City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Zip Code
                  </p>
                  <input
                    type="number"
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="px-5 py-2 rounded-lg bg-transparent border w-full"
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    State
                  </p>
                  <Select
                    value={selectedState}
                    defaultValue={selectedState}
                    // onValueChange={setSelectedState}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                    <SelectContent>
                      {usStates.map((country, i) => (
                        <SelectItem value={country} key={i}>
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="w-full">
                  <p className="text-sm font-semibold text-neutral-600 mb-1">
                    Country
                  </p>
                  <Select value={country}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Country" />
                    </SelectTrigger>
                    <SelectContent>
                      {countries.map((country, i) => (
                        <SelectItem value={country} key={i}>
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  disabled={isSaving}
                  onClick={handleSave}
                  className="px-5 py-2 rounded-md bg-green-700 text-white hover:bg-green-800 transition-all"
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 p-5 shadow-md shadow-neutral-100 rounded-2xl mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="w-full">
                <p className="text-sm font-semibold text-neutral-600 mb-1">
                  Current Password
                </p>
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="px-5 py-2 rounded-lg bg-transparent border w-full"
                />
              </div>
              <div className="w-full">
                <p className="text-sm font-semibold text-neutral-600 mb-1">
                  New Password
                </p>
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="px-5 py-2 rounded-lg bg-transparent border w-full"
                />
              </div>
            </div>
            <div className="flex items-center justify-end">
              <button
                disabled={isChangingPassword}
                onClick={handleChangePassword}
                className="px-5 py-2 rounded-md bg-green-700 text-white hover:bg-green-800 transition-all"
              >
                {isChangingPassword ? "Changing..." : "Change"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
